// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import "node_modules/bulma/sass/utilities/initial-variables";

// Brand colors
//$black:#5B5A5A;
//$blue: #023059;
//$red: #F26E50;
$black:#023059;
//Buttons
$primary: $black;
$dark:$black;
$secundary: #FFFFFF;
$warning: #F26E50;
$info: #8789FF;
$danger: #B39C3B;
$success: #FFE887;
//$primary-invert: #F0E2E7;
//$link: #568CE9;
$link-focus: #B39C3B;
$link-invert:#F26E50;
$link: #F26E50;


//Body
$body-background-color: #FFFFFF;
$body-color: #023059;
$family-home-title: 'Montserrat', sans-serif;//'Roboto', sans-serif;
$family-sans-serif: 'Roboto', sans-serif;
$family-HomeTitle:'Dancing Script', cursive;

// Update Bulma's global variables
$family-primary: $family-sans-serif;
$widescreen-enabled: true;
$fullhd-enabled: true;
$column-gap:1rem;

// Bulma's component variables Modification
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Import Bulma
@import "../../../node_modules/bulma/bulma.sass";