@charset "utf-8";

// Import Bulma scsss
@import './bulmaOverwrite.scss';


html{
    height: 100%;
    overflow-x: hidden;
    //overflow-y: auto;
    background-color: $body-background-color;
    color: $body-color;
   
    &.dark {
      color: $body-background-color;
      background-color: $body-color;
    }
}
button{
    z-index:10;
    margin-right:3px;
}
body {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
  //  height:100%;
    background-color: $body-background-color;
    color: $body-color;
    
   
    &.dark {
      color: $body-background-color;
      background-color: $body-color;
      .box{
        color: $body-background-color;
        background-color: $body-color;
        border: solid 0.5px $secundary;
        box-shadow: 0 4px 8px 0 $secundary, 0 6px 20px 0 $secundary;
      }
        .card{
            color: $body-background-color;
            background-color: $body-color;
            //border: solid 1px $body-color;
            box-shadow: 0 0px 0px 0 $body-color, 0 0px 0px 0 $body-color;
      }
      .second_line{
          background-color: $white;
          opacity: 0.6;
      }
      .first_line{
        background-color: $white;
        opacity: 0.6;
       }
      .third_line{
        background-color: $white;
        opacity: 0.6;
      }
      .four_line{
        background-color: $white;
        opacity: 0.6;
      }
      h1,h2,h3,h4,strong{
          color:$secundary;
      }
      a{
        color: $secundary;
      }
      a:hover{
          color: $danger;
          //font-weight: bolder;
      }
      #idRotatingText{
       
        color: $white;
    }
    }
  }

  a {
    color: $primary;
  }

// General
.box{
    overflow: hidden;
   
        color: $body-color;
        //background-color: $body-color;
       // border: solid 0.5px $primary;
        //box-shadow: 0 2px 4px 0 $primary, 0 3px 10px 0 $primary;
   
}

hr{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.section{ 
   padding-top: 10%;
}

#main_img{
    margin-top:15%;
}

h1{

    font-family:$family-home-title;
    font-size:6vw;
    position: relative;
    font-weight: bolder;
    
}

h2{

    font-family:$family-home-title;
    font-size:5vw;
    position: relative;
    font-weight: bolder;
    
}

h3{

    font-family:$family-home-title;
    font-size:4vw;
    position: relative;
    font-weight: bolder;
}

h4{

    font-family:$family-home-title;
    font-size:3vw;
    position: relative;
    font-weight: bolder;
   
}


ul{
   list-style-type:square;
   padding:4px;
   margin-left:16px;
}

li{
    margin-left: 25px;
    padding:4px;
    
 }
.card{
    z-index: 10;
    //border: solid 1px ;
    box-shadow: 0 0px 0px 0 $body-color, 0 0px 0px 0 $body-color;
}

 .center{
    
    text-align: center;
}
article{
    padding:8px;
}

.spinner_div {
    text-align: center;
    margin-top:10%;
}

//Home Page
.home_title{
    font-family:$family-home-title;
    font-size:7vw;
    margin-top:-12%;
    margin-left: -10%;
}

.home_title_strong{
      
    color:$warning;
    font-weight: lighter;
    font-size:13vw;
    text-transform: uppercase; 
   font-family:$family-home-title;
   z-index: 20;
}

.home_secundary{
    margin-top:-10%;  
    font-family:$family-home-title;
    font-size:8vw;
    z-index: 20;
    font-weight: lighter;
}

#idRotatingText{
    font-size:10vw;
   margin-left: -10.5%;
    text-align: left;
    color: $black;
    font-weight: lighter;
}

.signatureStyle{
    font-family:$family-home-title;
    font-size:8vw;    
}

.home_links{
    font-family:$family-sans-serif;
    font-size:2vw;
     margin-top:-30%;
     margin-left:5%;
     z-index: 20;
}

#parallax_section{
    z-index: initial;
}

#laptop_img{
    opacity:0.3;
    z-index:0;
}

#aboutMe{
    font-size:2vw;
    text-align: center;
}

.box{
    //color:#F26E50;
    background-color: $secundary;
}

.card-content{
   // color:#FFFFFF;
    background-color: $secundary;
}

.aboutImg{
    width: 200px;
    height: 200px;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0px 0px 0 rgba(0, 0, 0, 0) !important;
    
}

.card{
    z-index: 15 !important;
    opacity: 0.85;
}

img{
    z-index: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.image{
    z-index: 0;
}

.profile{
        border-radius: 5%;
        border-color: $black;
     
}

.profileImg{
    width: 80%;
    height: 80%;
   
}

#web_img{
    margin-top:-10%;
    margin-left: 0;
    width: 50%;
    height: 50%;
}
#theme_mode{
    margin-top:10px;
}

/* The animation code */
@keyframes front_end {
    
    0% {top: 1vw; bottom: 0; right: 0; left: 3.5vw;  opacity: 0.0;}
    /*10% {top: 20%; bottom: 0; right: 0; left: 10%;  opacity: 0.1;}
    20% {top: 0%; bottom: 0; right: 0; left: 20%;  opacity: 0.2;}
    30% {top: 30%; bottom: 0; right: 0; left: 30%;  opacity: 0.3;}
    40% {top: 0vw; bottom: 0; right: 0; left: 40%;  opacity: 0.4;}
    50% {top: 50%; bottom: 0; right: 0; left: 50%;  opacity: 0.5;}
    60% {top: 0vw; bottom: 0; right: 0; left: 60%; opacity: 0.6;}
    70% {top: 75%; bottom: 0; right: 0; left: 70%; opacity: 0.7;}
    80% {top: 0vw; bottom: 0; right: 0; left: 80%;  opacity: 0.8;}
    90% {top: 90%; bottom: 0; right: 0; left: 90%;  opacity: 0.9;}*/
    100% {top: 1vw; bottom: 0; right: 0; left: 3.5vw;  opacity: 0.7;}
        
  }

  @keyframes second_line_animation {
    
    0% {top: 20vw; bottom: 0; right: 0; left: 3.5vw; opacity: 0.0;}
   /* 10% {top: 2vw; bottom: 0; right: 0; left: 0%; opacity: 0.1;}
    20% {top: 14vw; bottom: 0; right: 0; left: 40%; opacity: 0.2;}
    30% {top: 4vw; bottom: 0; right: 0; left: 0%; opacity: 0.3;}
    40% {top: 12vw; bottom: 0; right: 0; left: 60%; opacity: 0.4;}
    50% {top: 2vw; bottom: 0; right: 0; left: 0%; opacity: 0.5;}
    60% {top: 0vw; bottom: 0; right: 0; left: 80%;opacity: 0.6;}
    70% {top: 4vw; bottom: 0; right: 0; left: 0%; opacity: 0.7;}
    80% {top: 8vw; bottom: 0; right: 0; left: 90%; opacity: 0.8;}
    90% {top: 12vw; bottom: 0; right: 0; left: 0%; opacity: 0.9;}*/
    100% {top: 20vw; bottom: 0; right: 0; left: 3.5vw; opacity: 0.7;}
        
  }

.first_line {
    position: absolute;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    top: 20vw;
    bottom: 0;
    right: 0;
    left: 3.5vw;
    width: /*361px*/25vw;
    height: 24vw;
    background: $success;
   // animation-name: second_line_animation;
    //animation-duration: 8s;
   // opacity: 0.7;
    z-index: 0;
   /* top: 50%;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: $success;
    opacity: 0.5;
    z-index: 0;
    transform: skewY(-40deg);
    transform-origin: top left;
    */
  }

   .second_line {
    position: absolute;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    top: 1vw;
    bottom: 0;
    right: 0;
    left: 3.5vw;
    width: /*361px*/25vw;
    height: 25vw;
    background: $warning;
   // animation-name: front_end;
    //animation-duration: 8s;
    //opacity: 0.7;
    z-index: 0;
    /*
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background: $warning;
    opacity: 0.5;
    z-index: 0;
    transform: skewY(-70deg);
    transform-origin: top left;
    */
  }

  .CookieConsent{

    font-family:$family-home-title;
    font-size:2vw;
    position: relative;
    margin:auto;
    font-weight: bolder;
    opacity: 0.9;

  }

  .spinner-div{
    width: 1200px;
    height: 1200px;
  }

  .center {
    margin: auto;
    width: 60%;
    padding: 10px;
    text-align: center;
  }

  .email-contact{
    font-family:$family-home-title;
    font-size:60%;
    position: relative;
    margin:auto;
    font-weight: bolder;
  
  }

  .watch-wrapper{
    width: 10vw;
    height: 10vw;
  }

  #rcc-confirm-button{
    font-size:1.5vw !important;
    font-weight: bolder;
    border-radius: 8px !important; 
    box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8) !important;
}



  